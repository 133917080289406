// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
// Variables
@import "variables";

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// navbar color
nav > div {
  background-color: $brand-primary !important;
}

.carousel {
  height: $carousel-size !important;
}

img.carousel-item {
  width: $carousel-size !important;
  height: $carousel-size !important;
}

img.carousel-item[onclick], img.thumbnail-image[onclick] {
  cursor: zoom-in;
}

img.modal-image-fullscreen {
    max-width:100%;
    max-height: 100%;
}

.thumbnail-image {
  width: $thumbnail-size;
  height: $thumbnail-size;
}

.thumbnail-image.main {
  border: 5px dashed $brand-primary;
}

.color-image {
    width: $color-size;
    height: $color-size;
}

.color-thumbnail-medium {
    width: 40px;
    height: 40px;
}

.color-thumbnail {
    width: $color-thumbnail-size;
    height: $color-thumbnail-size;
}

div#dimension-container > div {
  border-top: 1px solid grey;
}

div#dimension-container > div:first-child {
  border-top: initial;
}

// i, li.tab > a {
//     color: $brand-secondary !important;
// }

// a.btn> i, .btn> i {
//      color: white !important;
// }

input.waves-button-input {
    color: white !important;
}

a.collection-item {
    background-color:$brand-cardback !important;
    color: $brand-primary !important;
}

body {
    background-color: $brand-body !important;
    min-height: 100vh;
}

ul.tabs>.indicator {
    background-color: $brand-secondary !important;
}

.card, .card-panel, table, thead, th{
    //background-color: $brand-cardback !important;
    //background: $brand-cardback !important;
}

.tabs > li.tab:hover {
    //background-color: $brand-body-highlight;
}

.btn-info {
    background-color: #440044;
}

.btn-info:hover {
    background-color: #662266;
}

.btn-info:focus {
    background-color: #440044;
}

div.title.m-b-md {
  font-size: 48px !important;
}


//wishlist modal
img.list-img {
    margin-top: 10px;
    width: 80px !important;
    height: 80px !important;
}

li.collection-item.avatar.card {
    margin-bottom: 1vh;
}

.wishlist-choice-button {
    margin: 1vh;
}

.wishlist-choice-button>a {
    margin: 1vh;
    width: 100%;
    text-align: left
}

//datatables
td.details-control {
    background: url('https://datatables.net/examples/resources/details_open.png') no-repeat center center;
    cursor: pointer;
}

tr.shown td.details-control {
    background: url('https://datatables.net/examples/resources/details_close.png') no-repeat center center;
}

//materialize dropdown chrome fix
.dropdown-content {
    backface-visibility: hidden;
}

//Product image thumbnails crocodile-tortoise (keep aspect ratio, fill canvas(not fit), overflow hidden)
.card-image img {
  min-height: 100% !important;
  min-width: 100% !important;
  position: absolute !important;
  top: -9999px !important;
  bottom: -9999px !important;
  left: -9999px !important;
  right: -9999px !important;
  margin: auto !important;
}

//crocodile-tortoise with square image (break aspect ratio, fit canvas, no overflow)
// .card-image img {
//   width: 100%;
//   height: 100%;
// }

.card-image {
  width: 100%;
  height: 75%;
  max-height: 75%;
  position: relative;
  overflow: hidden;
}

.card-image-admin-update {
  width: 100%;
  height: 75%;
  max-height: 75%;
  position: relative;
  //overflow: hidden;
}