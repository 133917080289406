// Thumbnail size
$thumbnail-size: 200px;

//Color size
$color-size: 100px;

//Color thumbnail size
$color-thumbnail-size: 22px;

//Carousel
$carousel-size: 300px;

// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #26a69a;
//$brand-secondary: #cc00cc;
$brand-secondary: #26a69a;

$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;
$brand-body: #fefefe;
$brand-body-highlight: #e8e8e8;
//$brand-cardback: #ffe6ff;
$brand-cardback: #ffffff;


// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;


